// App.js
import React, { useState } from 'react';

function MyComponent(props) {
  const [email, setEmail] = useState('');

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log('Email submitted: ' + email);


    // Send the email to a server-side endpoint
    fetch('/api/logEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({ email: email }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json(); // Parse the response body as JSON
    })
    .then(data => {
      console.log(data); // Log the response body
      window.alert(JSON.stringify(data)); // Display the response body as an alert
    })
    .catch(error => {
      console.error('Error:', error);
      window.alert(`Error: ${error}`); // Display the error as an alert
    });
  };


  return (

    <div className="flex flex-col justify-center py-12 bg-white">
      <div className="mt-16 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:">
          <div className="flex flex-col w-[41%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              srcSet="images/aimart.png"
              className="grow w-full aspect-[0.9] max-md:max-w-full"
              alt="AI Mart"
            />
          </div>
          <div className="flex flex-col ml-5 w-[59%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-center px-5 mt-52 text-center max-md:mt-10 max-md:max-w-full">
              <div className="text-5xl text-neutral-600 max-md:max-w-full max-md:text-4xl">
                aiMart apps
              </div>
              <div className="self-stretch mt-7 text-2xl text-gray-700 max-md:max-w-full">
                Explore our portfolio of cutting-edge AI applications <br />
                and revolutionary products.
              </div>
              <div style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '10px', textAlign: 'center' }}>
                <h2 style={{ color: '#333' }}>Our apps are on their way</h2>
                <form onSubmit={handleFormSubmit} style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                  <input 
                    type="email" 
                    id="email" 
                    name="email" 
                    placeholder="Email address" 
                    value={email} 
                    onChange={handleInputChange} 
                    required 
                    style={{ padding: '10px', fontSize: '16px', width: '80%', maxWidth: '300px', borderRadius: '5px', border: '1px solid #ddd' }}
                  />
                  <button 
                    type="submit" 
                    style={{ display: 'block', backgroundColor: '#007BFF', color: '#fff', padding: '10px 20px', fontSize: '16px', borderRadius: '5px', border: 'none', marginTop: '10px', cursor: 'pointer' }}
                  >
                    Join waitlist!
                  </button>
                </form>
                <a href="https://blog.aimart.biz" style={{ color: '#007BFF', textDecoration: 'none', fontSize: '16px', marginTop: '10px' }}>blog</a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default MyComponent;
